import React from "react"
import classnames from "classnames"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { logW } from "src/core/helpers/log"
import { isFunction, objHasKey } from "src/core/helpers/utils"
import MyForm from "src/core/components/MyForm/MyForm"
import { MyUI } from "src/core/components"
interface IProps {
  className?: any
  style?: any
  type: string
}

interface IState {
  isShow?: boolean
  opts?: IConfigModal
}

interface IConfigModal {
  component?: any
  showFooter?: boolean
  modalClassName?: string
  modalCentered?: boolean
  modalScrollInside?: boolean
  title?: string
  usingModalBody?: boolean
  usingModalHeader?: boolean
  size?: string
  backdrop?: any | "static"
  fnList?: any
}

class MyModal extends React.Component<IProps, IState> {
  state: any = {
    isShow: false,
    opts: {},
  }
  componentDidMount() {
    const { type } = this.props
    MyModal.Helpers.initComponent(type, this)
  }
  show = (opts: any) => {
    this.setState({
      isShow: true,
      opts: { ...MyModal.Helpers.defaultConfigModal, ...opts },
    })
  }
  hide = () => {
    this.setState({
      isShow: false,
      opts: {},
    })
  }
  toggle = () => {
    this.setState({
      isShow: !this.state.isShow,
    })
  }
  _getConfig = key => {
    const { opts } = this.state
    if (objHasKey(opts, key)) {
      return opts[key]
    }
  }
  _renderContent = () => {
    let _ui = null
    const { type } = this.props
    const { opts, isShow } = this.state
    if (isShow !== true) {
      return <div></div>
    }
    switch (type) {
      case MyModal.Helpers.types.mydialog:
      case MyModal.Helpers.types.myui:
        return <MyUI {...opts?.configMyUI} />
      case MyModal.Helpers.types.component:
        if (opts.component) {
          if (isFunction(opts.component)) {
            return opts.component({
              inMyModal: this,
              fnList: opts.fnList,
              configFromModal: opts,
            })
          } else {
            return opts.component
          }
        } else {
          logW("Missing component")
          return <div></div>
        }
      case MyModal.Helpers.types.myform:
        if (opts.configForm) {
          return (
            <MyForm
              inMyModal={this}
              configFromModal={opts}
              configForm={opts.configForm}
              fnList={opts.configForm.fnList || {}}
            />
          )
        } else {
          logW("Missing configForm")
          return <div></div>
        }
    }
    return _ui
  }
  _renderBody = () => {
    const { opts } = this.state
    if (opts.usingModalBody === true) {
      return <ModalBody>{this._renderContent()}</ModalBody>
    }
    return this._renderContent()
  }
  _renderHeaderContent = () => {
    const { opts } = this.state
    return opts.title
  }
  _renderHeader = () => {
    const { opts } = this.state
    if (opts.usingModalHeader === true) {
      return (
        <ModalHeader toggle={this.toggle}>
          {this._renderHeaderContent()}
        </ModalHeader>
      )
    }
    return this._renderContent()
  }
  _getClassname = () => {
    const { opts } = this.state
    return classnames(opts.modalClassName, {
      "modal-dialog-centered": opts.modalCentered,
      [`modal-${opts.size}`]: true, //sm,lg,xl
      "modal-dialog-scrollable": opts.modalScrollInside,
    })
  }
  render() {
    const { isShow, opts } = this.state
    const { backdrop } = opts
    return (
      <Modal
        isOpen={isShow === true}
        fade
        backdrop={backdrop != null ? backdrop : true}
        toggle={this.toggle}
        className={this._getClassname()}
      >
        {this._renderHeader()}
        {this._renderBody()}
        {opts.showFooter === true && <ModalFooter></ModalFooter>}
      </Modal>
    )
  }
  static Helpers = {
    defaultConfigModal: {
      usingModalBody: true,
      usingModalHeader: true,
      size: "lg",
    },
    types: {
      component: "component",
      myui: "myui",
      myform: "myform",
      mypage: "mypage",
      lightbox: "lightbox",
      mydialog: "mydialog",
    },
    initComponent: (type, component) => {
      if (type && component) {
        MyModal.Helpers.components[type] = component
      }
    },
    components: {},
  }
  static showWithType = (type, opts) => {
    if (MyModal.Helpers.components[type]) {
      MyModal.Helpers.components[type].show(opts)
    }
  }
  static showFromMyUI = (configMyUI, configModal: IConfigModal | null) => {
    MyModal.showWithType(MyModal.Helpers.types.myui, {
      configMyUI: configMyUI,
      ...configModal,
    })
  }
  static showFromMyForm = (configForm, configModal: IConfigModal | null) => {
    MyModal.showWithType(MyModal.Helpers.types.myform, {
      configForm: configForm,
      ...configModal,
    })
  }
  static showFromComponent = (component, configModal: IConfigModal | null) => {
    MyModal.showWithType(MyModal.Helpers.types.component, {
      component: component,
      ...configModal,
    })
  }
  static showMyDialog = (configModal: IConfigModal | null) => {
    MyModal.showWithType(MyModal.Helpers.types.mydialog, {
      ...configModal,
    })
  }
  static hideModal = type => {
    if (MyModal.Helpers.components[type]) {
      MyModal.Helpers.components[type].hide()
    }
  }
}

export default MyModal
