import RcTooltip from "rc-tooltip"
import React from "react"
import "rc-tooltip/assets/bootstrap.css"
class RcPopover extends React.Component<any, any> {
  render() {
    if (this.props.overlay)
      return (
        <RcTooltip
          overlay={this.props.overlay}
          trigger={this.props.trigger || "click"}
        >
          <>{this.props.children}</>
        </RcTooltip>
      )
    return this.props.children
  }
}

export default RcPopover
