import React from "react"
import MyPageHelpers from "src/core/components/MyPage/MyPageHelpers"
import { CSuppense } from "src/core/components"

const PProfile = React.lazy(() => import("./Types/MyProfile"))
const PPayslipList = React.lazy(() => import("./Types/PayslipList"))
const PPayslipDetail = React.lazy(() => import("./Types/PayslipDetail"))

const MyPageOne = {
  init() {
    MyPageHelpers.addTypes("myprofile", props => {
      return CSuppense(props, PProfile)
    })
    MyPageHelpers.addTypes("paysliplist", props => {
      return CSuppense(props, PPayslipList)
    })
    MyPageHelpers.addTypes("payslipdetail", props => {
      return CSuppense(props, PPayslipDetail)
    })
  },
}

export default MyPageOne
