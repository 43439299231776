import React from "react"
const OptionsInfo = {
  fValue: "Value",
  fText: "Text",
}
export const getListSelectTagBySourceData = (
  sourceData?: any[],
  opts: any = {}
) => {
  if (sourceData && sourceData.length > 0) {
    let _ui: any = []
    if (opts) {
      if (opts.noAddAutoEmpty !== true) {
        _ui.push(
          <option key={"_empty_"} value={""}>
            {""}
          </option>
        )
      }
    }
    for (let i = 0; i < sourceData.length; i++) {
      let _text = sourceData[i].Text || sourceData[i].label || ""
      let _value = sourceData[i].Value || sourceData[i].value || ""
      _ui.push(
        <option key={i} value={_value}>
          {_text}
        </option>
      )
    }
    return _ui
  }
}

export const convertSourceDataToString = (sourceData?: any[]) => {
  if (sourceData) {
    return sourceData.join(",")
  }
  return ""
}

export const convertSourceDataToArrayValue = (sourceData?: any[]) => {
  if (sourceData) {
    return sourceData.map((v: any, i) => {
      return v[OptionsInfo.fValue]
    })
  }
  return []
}

export const getSourceDataFromSourceList = (sourceList: any, key: string) => {
  if (sourceList && sourceList[key]) {
    return sourceList[key]
  }
  return []
}

export const getSourceListFromFnList = (fnList: any) => {
  if (fnList && fnList.getSourceList) {
    return fnList.getSourceList()
  }
  return {}
}
