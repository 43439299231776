import React from "react"
import { MyFormControlHelpers, CSuppense } from "@macashipo/mlib"

const V1_mfc_mycontrol = React.lazy(
  () => import("src/core/modules/v1page/MyForm/Types/v1_mfc_mycontrol")
)

const ListControl = {
  esc_date_flatpickr: "esc_date_flatpickr",
}
const MyFormControlOne = {
  init() {
    for (let k of Object.keys(ListControl)) {
      MyFormControlHelpers.addTypes(k, props => {
        return CSuppense({ ...props, typeControl: k }, V1_mfc_mycontrol)
      })
    }
  },
}

export default MyFormControlOne
