// import * as React from 'react'
// import styles from './styles.module.css'
import CPagination from './configs/ConfigPagination'
import CSuppense from './components/MyComponent/CSuppense'
import CErrorBoundary from './components/MyComponent/CErrorBoundary'
import ConfigPage from './configs/ConfigPage'
import ConfigSearch from './configs/ConfigSearch'

import OnePage from './model/OnePage'
import OneTableCell from './model/OneTableCell'
import OneFilter from './model/OneFilter'
import OneButton from './model/OneButton'
import OneTableFilterControl from './model/OneTableFilterControl'
import HFormat from './helpers/Format'
import * as HUtils from './helpers/Utils'
import * as HLink from './helpers/Link'
import * as HLog from './helpers/Log'
import * as HJson from './helpers/Json'
import * as HIs from './helpers/Is'
import * as HUI from './helpers/UI'
import * as HRegex from './helpers/Regex'
import HFilter from './helpers/Filter'
import HMore from './helpers/More'
import * as HOptions from './helpers/Options'
import * as HLocalStorage from './helpers/LocalStorage'
import * as HColor from './helpers/Color'
import * as HText from './helpers/Text'
import HModules from './helpers/Modules'
import HFakeData from './helpers/FakeData'
import HValid from './helpers/Valid'
import * as HCF from './helpers/CF'
import HEE from './helpers/EventEmitter'

//MyCom
import MyComponentHelpers from './components/MyComponent/Helpers'

import MyPage from './components/MyPage'
import MyPageHelpers from './components/MyPage/Helpers'
import MyPageLayout from './components/MyPageLayout'
import MyPageLayoutHelpers from './components/MyPageLayout/Helpers'
import MyUI from './components/MyUI'
import MyUIHelpers from './components/MyUI/Helpers'
import MyCard from './components/MyCard'
import MyCardHelpers from './components/MyCard/Helpers'
import MyLayout from './components/MyLayout'
import MyLayoutHelpers from './components/MyLayout/Helpers'
import MyTableCell from './components/MyTableCell'
import MyTableCellHelpers from './components/MyTableCell/Helpers'
import MyTableFilterControl from './components/MyTableFilterControl'
import MyTableFilterControlHelpers from './components/MyTableFilterControl/Helpers'
import MyFormControl from './components/MyFormControl'
import MyFormControlHelpers from './components/MyFormControl/Helpers'
import MyButton from './components/MyButton'
import MyButtonHelpers from './components/MyButton/Helpers'
import MyIcon from './components/MyIcon'
import MyIconHelpers from './components/MyIcon/Helpers'
import MyLoading from './components/MyLoading'
import MyLoadingHelpers from './components/MyLoading/Helpers'

import MyControl from './components/MyControl'
import MyControlHelpers from './components/MyControl/Helpers'
import MyControl_Ext from './components/MyControl/MyControl_Ext'
import MyControl_Ext_Select from './components/MyControl/MyControl_Ext_Select'
// import * as HUtils from './helpers/Utils'
// export { HUtils } from './utils'
// interface Props {
//   text: string
// }

// export const ExampleComponent = ({ text }: Props) => {
//   return <div className={styles.test}>Test Example Component: {text}</div>
// }

//V1
import * as V1 from './v1'

const Allias = {
  HApi: {},
  showPhotosViewer: (arrPhotos: any[] = [], currentIndex: number = 0) => {
    console.warn('need overide showPhotosViewer', arrPhotos, currentIndex)
  }
}

const addAllias = (opts: { HApi: any; showPhotosViewer: any }) => {
  if (opts.HApi) {
    Allias.HApi = opts.HApi
  }
  if (opts.showPhotosViewer) {
    Allias.showPhotosViewer = opts.showPhotosViewer
  }
}
export {
  addAllias,
  CPagination,
  CSuppense,
  ConfigSearch,
  CErrorBoundary,
  ConfigPage,
  OnePage,
  OneButton,
  OneTableCell,
  OneTableFilterControl,
  OneFilter,
  HUtils,
  HLink,
  HFormat,
  HModules,
  HLog,
  HIs,
  HUI,
  HText,
  HJson,
  HRegex,
  HFilter,
  HColor,
  HMore,
  HOptions,
  HLocalStorage,
  HFakeData,
  HValid,
  HCF,
  HEE,
  MyComponentHelpers,
  MyPage,
  MyPageHelpers,
  MyPageLayout,
  MyPageLayoutHelpers,
  MyUI,
  MyUIHelpers,
  MyCard,
  MyCardHelpers,
  MyLayout,
  MyLayoutHelpers,
  MyTableCell,
  MyTableCellHelpers,
  MyTableFilterControl,
  MyTableFilterControlHelpers,
  MyFormControl,
  MyFormControlHelpers,
  MyButton,
  MyButtonHelpers,
  MyIcon,
  MyIconHelpers,
  MyLoading,
  MyLoadingHelpers,
  MyControl,
  MyControlHelpers,
  MyControl_Ext,
  MyControl_Ext_Select,
  V1
}
