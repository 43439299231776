import React from "react"
import MyPageHelpers from "src/core/components/MyPage/MyPageHelpers"
import { CSuppense } from "src/core/components"

const PDev_ListMyControl = React.lazy(() => import("./Types/Dev_ListMyControl"))
const PDev_CategoryList = React.lazy(() => import("./Types/Dev_CategoryList"))
const PDev_MyPage = React.lazy(() => import("./Types/Dev_MyPage"))
const PDev_MyForm = React.lazy(() => import("./Types/Dev_MyForm"))
const PDev_Notification = React.lazy(() => import("./Types/Dev_Notification"))
const PDev_Other = React.lazy(() => import("./Types/Dev_Other"))
const PDev_Resize = React.lazy(() => import("./Types/Dev_Resize"))
const PDev_UseScript = React.lazy(() => import("./Types/Dev_UseScript"))
const PDev_CustomHook = React.lazy(() => import("./Types/Dev_CustomHook"))

const MyPageOne = {
  init() {
    MyPageHelpers.addTypes("dev_listmycontrol", props => {
      return CSuppense(props, PDev_ListMyControl)
    })
    MyPageHelpers.addTypes("dev_categorylist", props => {
      return CSuppense(props, PDev_CategoryList)
    })
    MyPageHelpers.addTypes("dev_mypage", props => {
      return CSuppense(props, PDev_MyPage)
    })
    MyPageHelpers.addTypes("dev_myform", props => {
      return CSuppense(props, PDev_MyForm)
    })
    MyPageHelpers.addTypes("dev_notification", props => {
      return CSuppense(props, PDev_Notification)
    })
    MyPageHelpers.addTypes("dev_other", props => {
      return CSuppense(props, PDev_Other)
    })
    MyPageHelpers.addTypes("dev_resize", props => {
      return CSuppense(props, PDev_Resize)
    })
    MyPageHelpers.addTypes("dev_hook", props => {
      return CSuppense(props, PDev_UseScript)
    })
    MyPageHelpers.addTypes("dev_customhook", props => {
      return CSuppense(props, PDev_CustomHook)
    })
  },
}

export default MyPageOne
