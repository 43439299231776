import React from "react"

interface IProps {
  controlOpts?: IControlOpts
  value?: any
  onChange?: (ev: any) => void
  invalid?: any
  defaultValue?: any
}

class Control_Ext extends React.Component<IProps, any> {
  getDefaultValue = () => {
    return this.props.defaultValue
  }
  getControlOpts = () => {
    const { controlOpts } = this.props
    return controlOpts || {}
  }
  getFieldName = () => {
    const { controlOpts } = this.props
    if (controlOpts && controlOpts.fieldName) {
      return controlOpts.fieldName
    }
    return ""
  }
  getOnUpdateValue = () => {
    const { controlOpts } = this.props
    console.warn("getOnUpdateValue", controlOpts)
    if (controlOpts && controlOpts.onUpdateValue) {
      return controlOpts.onUpdateValue
    }
    return () => {
      console.warn("Missing onUpdateValue on controlOpts", this.props)
    }
  }
  onUpdateValue = v => {
    const onUpdateValue = this.getOnUpdateValue()
    if (onUpdateValue) {
      onUpdateValue(v)
    }
  }
  getFormat = () => {
    const controlOpts = this.getControlOpts()
    return controlOpts.format
  }
  render() {
    return <div></div>
  }
}

export default Control_Ext
