import { toast } from "react-toastify"
import { MyDialog } from "src/core/components"
const configToast = {
  hideProgressBar: true,
  pauseOnHover: true,
  autoClose: 2000,
}

const ToastType = {
  success: "success",
  warn: "warn",
  warning: "warning",
  info: "info",
  error: "error",
}

export const showToast = (msg, options) => {
  let _options = { ...configToast, ...options }
  let _type = options.type || ToastType.success
  switch (_type) {
    case ToastType.success:
      toast.success(msg, _options)
      break
    case ToastType.warn:
      toast.warn(msg, _options)
      break
    case ToastType.warning:
      toast.warn(msg, _options)
      break
    case ToastType.info:
      toast.info(msg, _options)
      break
    case ToastType.error:
      toast.error(msg, _options)
      break
  }
}

export const showToastSuccess = msg => {
  showToast(msg, {})
}

export const showToastError = msg => {
  if (msg) {
    showToast(msg, {
      type: ToastType.error,
    })
  }
}

export const showToastLikeToast = msg => {
  if (msg) {
    showToast(msg, {
      type: ToastType.success,
      position: toast.POSITION.BOTTOM_CENTER,
      style: {
        background: "black",
        color: "white",
      },
    })
  }
}

export const showApiMsg = msg => {
  if (msg) {
    showToastSuccess(msg)
  }
}

export const hideInMyModalFromProps = props => {
  if (props && props.inMyModal && props.inMyModal.hide) {
    props.inMyModal.hide()
  }
}

export const showDialog = (configDialog: any) => {
  console.warn("showDialog", configDialog)
  MyDialog.show({
    ...configDialog,
  })
}
