import { IHModules, IOneModule } from '../interface'
const Modules: IHModules = {
  list: [],
  moduleByKey: {},
  configModules: {},
  initModules(list: IOneModule[], configModules: any, HConfig: any) {
    Modules.list = list
    Modules.configModules = configModules
    if (Modules.list && Modules.list.length > 0) {
      for (let i = 0; i < Modules.list.length; i++) {
        let _oneModule = Modules.list[i]
        if (_oneModule) {
          let _moduleKey = ''
          if (_oneModule._key) {
            _moduleKey = _oneModule._key
          } else if (_oneModule.getMyKey) {
            _moduleKey = _oneModule.getMyKey()
          }
          if (_oneModule.runAfterInit) {
            _oneModule.runAfterInit(HConfig)
          }
          if (_moduleKey) {
            Modules.moduleByKey[_moduleKey] = _oneModule
          }
        }
      }
    }
  },
  getConfigModuleByKey(key: string) {
    return Modules.configModules[key]
  },
  //for one module
  initDefault(module: any = {}, listComponent: any = {}) {
    if (module && module._key) {
      Modules.addUI(module, listComponent)
      Modules.addRoutes(module)
      Modules.registerFnList(module)
    } else {
      console.warn('missing module key', module)
    }
  },
  //add MyComponent
  addUI(module: any = {}, listComponent: any = {}) {
    if (module && module._key) {
      let _keys = Object.keys(listComponent)
      for (let k of _keys) {
        if (listComponent[k] && listComponent[k].init) {
          listComponent[k].init()
        }
      }
    }
  },
  //========== fnList
  ModuleFnList: {},
  registerFnList(module: any = {}) {
    if (module && module._key && module._fnList) {
      Modules.ModuleFnList[module._key] = module._fnList
    }
  },
  runFunctionInModuleFnList(moduleKey = '', fnName = '', args = []) {
    if (
      Modules.ModuleFnList[moduleKey] &&
      Modules.ModuleFnList[moduleKey][fnName]
    ) {
      Modules.ModuleFnList[moduleKey][fnName].apply(null, args)
    } else {
      console.warn(
        'Missing fn module',
        moduleKey,
        fnName,
        Modules.ModuleFnList[moduleKey]
      )
    }
  },
  //========== routes
  addRoutes(module: any = {}, opts: any = {}) {
    if (module && module._key && module._routes) {
      var fnAddRoutes = null
      if (opts && opts.fnAddRoutes) {
        fnAddRoutes = opts.fnAddRoutes
      }
      if (fnAddRoutes) {
        fnAddRoutes(module._routes)
      }
    }
  }
}

export default Modules
