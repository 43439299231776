import ReactGA from "react-ga"
import { HModules, HCF, HLink } from "@macashipo/mlib"
const ModuleReactGA = {
  _key: "reactga",
  _fnList: {
    gaTrackCurrentPageView: () => {
      console.warn("gaTrackCurrentPageView")
      if (ModuleReactGA._config.initGAdone) {
        if (ModuleReactGA._config.isUsingDev) {
          ReactGA.pageview(`/${HCF.getCF("appName")}/${window.location.href}`)
        } else {
          ReactGA.pageview(
            window.location.pathname +
              window.location.search +
              window.location.hash
          )
        }
      }
    },
    gaTrackPageView: ({ page } = {}) => {
      if (ModuleReactGA._config.initGAdone) {
        ReactGA.pageview(page)
      }
    },
    gaTrackEvent: ({ category, action }) => {
      if (ModuleReactGA._config.initGAdone) {
        ReactGA.event({
          category: category,
          action: action,
        })
      }
    },
  },
  _config: {
    initGAdone: false,
    isUsingDev: false,
    GAIdDev: "UA-119777650-2",
  },
  getMyKey: () => {
    return "reactga"
  },
  runAfterInit: HConfig => {
    HModules.initDefault(ModuleReactGA, {})
    var GAId = HCF.getCF("GAId") || ModuleReactGA._config.GAIdDev
    if (GAId) {
      ReactGA.initialize(GAId)
      ModuleReactGA._config.initGAdone = true
      if (GAId === ModuleReactGA._config.GAIdDev) {
        ModuleReactGA._config.isUsingDev = true
      }
      HModules.runFunctionInModuleFnList(
        "reactga",
        "gaTrackCurrentPageView",
        []
      )
      const history = HLink.getHistory()
      if (history && history.listen) {
        history.listen(h => {
          HModules.runFunctionInModuleFnList(
            "reactga",
            "gaTrackCurrentPageView",
            []
          )
        })
      }
    }
  },
}

export default ModuleReactGA
