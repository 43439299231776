import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap"
import { HAuth } from "src/core/helpers"
import { MyUI } from "src/core/components"
const Avatar = props => {
  const { t } = useTranslation()
  const history = useHistory()
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const toggle = () => setDropdownOpen(prevState => !prevState)

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle outline color="link" className="p-0 p-user">
        <span className="title-user">
          {t("headermenu.Hi user", { name: HAuth.getUserName() })}
        </span>
        <MyUI
          type="avatar"
          alt={t("headermenu.Hi user", { name: HAuth.getUserName() })}
          src={HAuth.getUserAvatar()}
          className="rounded-pill"
          style={{ width: "30px", height: "30px", objectFit: "cover" }}
        />
        {/* <img
          alt={t("headermenu.Hi user", { name: HAuth.getUserName() })}
          src={HAuth.getUserAvatar()}
          className="rounded-pill"
          style={{ width: "30px", height: "30px" }}
        /> */}
      </DropdownToggle>
      <DropdownMenu style={{ maxWidth: "200px" }} right={true}>
        <DropdownItem header className="text-truncate">
          {HAuth.getUserName()}
        </DropdownItem>
        <DropdownItem divider />
        {/* <DropdownItem header>{t("headermenu.Account")}</DropdownItem> */}
        <DropdownItem
          onClick={() => {
            history.push("/my-profile")
          }}
        >
          {t("headermenu.Profile")}
        </DropdownItem>
        <DropdownItem
          onClick={() => {
            history.push("/setting")
          }}
        >
          {t("headermenu.Setting")}
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem onClick={props.handleLogout}>
          {t("headermenu.Logout")}
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}

export default Avatar
