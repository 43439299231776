import * as React from 'react'
import { json2Obj } from '../helpers/Json'
import ConfigOnClick from '../configs/ConfigOnClick'
import { isJsonString, isObject, isString, isNullOrEmpty } from 'src/helpers/Is'
import { getTextWB, validColorBootstrap } from 'src/helpers/Color'
import { logParseJson } from 'src/helpers/Log'
import { getTextDisplayOfValueFromOptions } from '../helpers/Options'
import {
  IMoreOfCell,
  IFnListOfCell,
  IMoreOfCellConfigButton,
  IMoreOfCellConfigTextDisplay,
  IMoreOfCellConfigTooltip,
  IMoreOfCellConfigApi,
  IMoreOfCellConfigModal,
  IMoreOfCellConfigForm,
  IMoreOfCellConfigHtml
} from '../interface'
import {
  runFuntion,
  getTextWithData,
  // mergeObjIfExist,
  mergeObjIfNotExist,
  getCustomData
} from 'src/helpers/Utils'
import MyIcon from 'src/components/MyIcon'
interface IOneTableCell {}

interface IOneTableCellOpts {
  row?: any
  cell?: any
  extra?: any
  configColumn?: any
  fieldName?: string
  onePage?: IOnePage
}

interface IOnePage {
  getApiController: () => string | any
  getQueryShared: () => any
  getSourceList: () => any
}

class OneTableCell implements IOneTableCell {
  row: any
  extra: any
  //extra
  fnList: IFnListOfCell = {}
  type: string = ''
  more: IMoreOfCell = {}
  fieldName: string = ''
  _onePage?: IOnePage
  _configColumn?: any
  constructor(opts: IOneTableCellOpts) {
    if (opts) {
      if (opts.extra && opts.row) {
        this.init(opts.row, opts.extra)
      } else if (opts.configColumn && opts.fieldName) {
        this.initFromConfigColumn(opts.configColumn, opts.fieldName)
      }
    }
  }
  updateOnePage(onePage?: IOnePage) {
    if (onePage) {
      this._onePage = onePage
    }
  }
  initFromConfigColumn(configColumn: any, fieldName: string) {
    this._configColumn = configColumn
    this.fieldName = fieldName
    if (this._configColumn && this._configColumn.Type) {
      this.parseExtraType(this._configColumn)
    }
  }
  parseExtraType(extra: any) {
    if (extra.Type) {
      if (extra.Type.type) {
        this.type = extra.Type.type
      }
      if (extra.Type.more) {
        this.more = json2Obj(extra.Type.more, {})
      }
    }
  }
  update(row: any, extra: any) {
    this.row = row
    this.extra = extra
    if (extra) {
      if (extra.fieldName) {
        this.fieldName = extra.fieldName
      }
      if (extra.fnList) {
        this.fnList = extra.fnList
      }
      this.parseExtraType(extra)
    }
  }
  init(row: any, extra: any) {
    this.update(row, extra)
  }
  getMore() {
    return this.more
  }
  getRowId() {
    if (this.row) return this.row.Id
    return null
  }
  getValue() {
    if (this.row && this.fieldName) {
      return this.row[this.fieldName]
    }
  }

  //Get More Config
  getExtraStyle({
    customMore,
    dfStyle,
    customStyle
  }: {
    customMore?: any
    dfStyle?: any
    customStyle?: any
  } = {}) {
    const more = customMore || this.more
    let _style = dfStyle || {}
    if (this.extra) {
      //style from extra.Style
      let _styleEx = this.extra.Style
      if (_styleEx != null) {
        if (isString(_styleEx) == true) {
          _style = Object.assign(_style, json2Obj(_styleEx, {}))
        } else if (isObject(_styleEx)) {
          _style = Object.assign(_style, _styleEx)
        }
      }

      //get bg va color from Type
      if (this.extra.Type != null && this.row != null) {
        if (this.extra.Type.bgF != null) {
          if (this.row[this.extra.Type.bgF] != null) {
            let _color = getTextWB(this.row[this.extra.Type.bgF])
            _style = Object.assign(_style, {
              background: this.row[this.extra.Type.bgF],
              color: _color
            })
          } else if (
            this.extra.Type.bgF.startsWith('#') ||
            this.extra.Type.bgF.startsWith('linear-gradient')
          ) {
            let _color = getTextWB(this.extra.Type.bgF)
            _style = Object.assign(_style, {
              background: this.extra.Type.bgF,
              color: _color
            })
          }
        }
        if (this.extra.Type.colorF != null) {
          if (this.extra.Type.colorF.startsWith('#')) {
            _style = Object.assign(_style, { color: this.extra.Type.colorF })
          } else if (this.row[this.extra.Type.colorF] != null) {
            _style = Object.assign(_style, {
              color: this.row[this.extra.Type.colorF]
            })
          }
        }
      }

      //from more
      if (more) {
        try {
          if (more.style != null) {
            _style = Object.assign(_style, more.style)
          }
          if (more.colorF != null) {
            if (this.row[more.colorF] != null) {
              _style = Object.assign(_style, {
                color: this.row[more.colorF]
              })
            }
          }
        } catch (error) {
          logParseJson(more)
        }
      }
    }

    //from row
    if (this.row) {
      if (this.row.StyleRow && isObject(this.row.StyleRow)) {
        _style = Object.assign(_style, this.row.StyleRow)
      }
      if (this.row.UI_StyleRow) {
        if (isObject(this.row.UI_StyleRow)) {
          _style = Object.assign(_style, this.row.UI_StyleRow)
        } else if (isJsonString(this.row.UI_StyleRow)) {
          try {
            let _styleRow = json2Obj(this.row.UI_StyleRow)
            if (_styleRow) {
              _style = Object.assign(_style, _styleRow)
            }
          } catch (error) {
            logParseJson(this.row.UI_StyleRow)
          }
        }
      }
    }
    if (customStyle) {
      _style = Object.assign(customStyle, _style)
    }

    return _style
  }
  getStyleContainer({ customMore }: { customMore?: any } = {}) {
    let _style: any = {}
    const more = customMore || this.more
    const fnList = this.fnList
    if (more && (more.configExpandArea || more.hasExpandArea)) {
      let _configExpandArea = more.configExpandArea
      if (
        more.hasExpandArea ||
        (_configExpandArea && _configExpandArea.show === true)
      ) {
        //tuong duong more.hasExpandArea
        let _currentLevelDetail = runFuntion(fnList, 'fntdGetExpandLevel', [])
        if (_currentLevelDetail != null) {
          let _paddingLeft = 20
          if (_configExpandArea && _configExpandArea.paddingLevel != null) {
            _paddingLeft = _configExpandArea.paddingLevel
          }
          _style.paddingLeft = `${_currentLevelDetail * _paddingLeft}px`
          _style.position = 'relative'
          _style.background = 'transparent'
          _style.display = 'flex'
          _style.flexDirection = 'row'
        }
      }
    }
    if (more && more.styleContainer) {
      _style = Object.assign(_style, more.styleContainer)
    }
    return _style
  }
  getExtraClassName({ customMore }: { customMore?: any } = {}) {
    const more = customMore || this.more
    let _className = ''
    if (this.extra != null) {
      if (this.extra.ClassName) {
        _className += ' '
        _className += this.extra.ClassName
      }
    }
    if (more) {
      if (more.className) {
        _className += ' '
        _className += more.className
      }
      if (more.classNameWithColField) {
        if (isString(more.classNameWithColField)) {
          _className += ' '
          _className += `${more.classNameWithColField}-${this.fieldName}`
        } else if (more.classNameWithColField === true) {
          _className += ' '
          _className += `col-${this.fieldName}`
        }
      }
      if (more.classNameOnly) {
        return more.classNameOnly
      }
    }

    return _className
  }

  getOnClick() {
    return ConfigOnClick.getOnClickFromMoreCell(
      this.fnList,
      this.row,
      this.more.configOnClick
    )
  }
  getConfigApi = ({ customMore }: { customMore?: any } = {}) => {
    const more = customMore || this.more
    const row = this.row
    if (more.configApi) {
      return more.configApi
    }
    let _configApi: IMoreOfCellConfigApi = {
      method: more.method || 'POST',
      url: more.url || more.apiUrl, //more.apiUrl//apiPath//apiName//apiNameAdd//
      needReloadList: more.needReload || more.needReloadList || false,
      needReloadPage: more.needReloadPage,
      needReExpand: more.needReExpand,
      needSendMsg: more.needSendMsg,
      needReloadOptionsAndList: more.needReloadOptionsAndList,
      needReloadRow: more.needReloadRow,
      query: more.query || {}
    }
    if (isNullOrEmpty(_configApi.url)) {
      _configApi.path = more.apiPath || this.getApiController()
      _configApi.name = more.apiName || more.apiNameAdd || ''
      if (_configApi.name && _configApi.name.indexOf('[') > -1) {
        _configApi.name = getTextWithData(_configApi.name, row)
      }
    } else {
      if (_configApi.url && _configApi.url.indexOf('[') > -1) {
        _configApi.url = getTextWithData(_configApi.url, row)
      }
    }

    _configApi.query = this.parseQueryFromConfigQuery(_configApi.query)
    if (_configApi.url || _configApi.path) {
      return _configApi
    }
    return null
  }
  parseQueryFromConfigQuery = (query: any = {}) => {
    const row = this.row
    let _keys = Object.keys(query)
    let _newQuery = {}
    if (_keys && _keys.length > 0) {
      for (let k of _keys) {
        if (isString(query[k]) && row.hasOwnProperty(query[k])) {
          _newQuery[k] = row[query[k]]
        } else {
          _newQuery[k] = query[k]
        }
      }
    }
    return _newQuery
  }
  getApiController() {
    if (this._onePage) {
      return this._onePage.getApiController()
    }
  }
  getConfigButton({ customMore }: { customMore?: any } = {}) {
    let _configButton: IMoreOfCellConfigButton = {}
    const more = customMore || this.more
    if (more) {
      if (more.configButton && isObject(more.configButton)) {
        _configButton = more.configButton
      } else if (more.button && isObject(more.button)) {
        _configButton = more.button
      }
    }
    return _configButton
  }
  getMorePropsButton({ customMore }: { customMore?: any } = {}) {
    const more = customMore || this.more
    const row = this.row
    let _props: any = {}
    mergeObjIfNotExist(_props, {
      outline: false,
      color: 'primary',
      className: '',
      style: {}
    })
    if (more) {
      if (more.fVisible) {
        if (
          row[more.fVisible] === false ||
          row[more.fVisible] == null ||
          row[more.fVisible] == ''
        ) {
          _props.style.visibility = 'hidden'
        }
      }
      //style by value field
      if (more.styleByValueField && more.styleByValueField.field) {
        // console.warn("stylebyValueField",_more);
        let _v = row[more.styleByValueField.field]
        let _objSwitch = more.styleByValueField.switch
        if (_objSwitch) {
          if (_v && _objSwitch[_v]) {
            _props.style = Object.assign(_props.style, _objSwitch[_v])
          } else if (_objSwitch.default) {
            _props.style = Object.assign(_props.style, _objSwitch.default)
          }
        }
      }
    }
    let _configButton = this.getConfigButton({ customMore })
    if (_configButton) {
      if (_configButton.outline != null) {
        _props.outline = _configButton.outline
      }
      if (_configButton.color != null) {
        _props.color = _configButton.color
      }
      if (_configButton.className != null) {
        _props.outline = _configButton.className
      }
      if (_configButton.style != null) {
        _props.style = Object.assign(_props.style, _configButton.style)
      }
      if (_configButton.fColor && row[_configButton.fColor]) {
        if (validColorBootstrap(row[_configButton.fColor])) {
          _props.color = row[_configButton.fColor]
        } else if (_props.style) {
          _props.style.color = row[_configButton.fColor]
        }
      }
      if (_configButton.fDisabled != null) {
        //
        if (typeof _configButton.fDisabled == 'boolean') {
          _props.disabled = _configButton.fDisabled
        } else if (typeof row[_configButton.fDisabled] == 'boolean') {
          _props.disabled = row[_configButton.fDisabled]
        }
      }
      if (_configButton.fNotDisabled != null) {
        if (typeof _configButton.fNotDisabled == 'boolean') {
          _props.disabled = !_configButton.fNotDisabled
        } else if (typeof row[_configButton.fNotDisabled] == 'boolean') {
          _props.disabled = !row[_configButton.fNotDisabled]
        }
      }
      if (_configButton.props) {
        _props = Object.assign(_props, _configButton.props)
      }
    }

    return _props
  }

  getConfigTextDisplay({ customMore }: { customMore?: any } = {}) {
    let _configTextDisplay: IMoreOfCellConfigTextDisplay = {}
    const more = customMore || this.more
    if (more && more.configTextDisplay) {
      return more.configTextDisplay
    } else {
      if (more) {
        if (more.fDisplay || more.fShow) {
          _configTextDisplay.fDisplay = more.fDisplay || more.fShow
        }
        if (more.hasIconAndText) {
          _configTextDisplay.hasIconAndText = more.hasIconAndText
        }
        if (more.fIcon) {
          _configTextDisplay.fIcon = more.fIcon
        }
        if (more.icon) {
          _configTextDisplay.icon = more.icon
        }
      }
    }
    return _configTextDisplay
  }
  getTextDisplay({
    df,
    icon,
    title,
    hasIconAndText = false,
    options,
    optionValue,
    customMore
  }: {
    df?: any
    icon?: any
    title?: any
    hasIconAndText?: boolean
    options?: any
    optionValue?: any
    customMore?: any
  } = {}) {
    const row = this.row
    const cell = this.getValue()
    const configTextDisplay = this.getConfigTextDisplay({ customMore })
    let _display: any = df || ''
    let _icon: any = icon || configTextDisplay.icon
    let _uiIcon: any = null
    let _hasIconAndText = hasIconAndText || configTextDisplay.hasIconAndText
    // console.warn("getTextDisplay:",_more,_display);
    if (configTextDisplay) {
      if (configTextDisplay.fDisplay && row[configTextDisplay.fDisplay]) {
        _display = row[configTextDisplay.fDisplay]
      } else if (options && optionValue) {
        _display =
          getTextDisplayOfValueFromOptions(options, optionValue) || cell
      } else if (title) {
        _display = title
      } else if (cell) {
        _display = cell
      }

      if (_icon) {
        _uiIcon = <MyIcon configIcon={_icon} />
      } else if (configTextDisplay.fIcon && row[configTextDisplay.fIcon]) {
        _uiIcon = <MyIcon configIcon={row[configTextDisplay.fIcon]} />
      }

      if (_uiIcon) {
        if (_hasIconAndText === true) {
          _display = (
            <div>
              {_uiIcon} {_display}
            </div>
          )
        } else {
          _display = _uiIcon
        }
      }
    }
    return _display
  }
  getDateFormat({
    customMore,
    dfFormat
  }: { customMore?: any; dfFormat?: any } = {}) {
    const more = customMore || this.more
    let _format = dfFormat || ''
    if (more && more.dateFormat) {
      _format = more.dateFormat
    } else if (more.format) {
      _format = more.format
    }
    return _format
  }
  getListAction = ({ customMore }: { customMore?: any } = {}) => {
    const more = customMore || this.more
    return more.listAction
  }
  getConfigTooltip({ customMore }: { customMore?: any } = {}) {
    const more = customMore || this.more
    let _configTooltip: IMoreOfCellConfigTooltip = {}
    if (more) {
      if (more.configTooltip) {
        return more.configTooltip
      } else {
        if (more.fTooltip || more.tooltipF) {
          _configTooltip.fTooltip = more.fTooltip || more.tooltipF
        }
        if (more.tooltip) {
          _configTooltip.tooltip = more.tooltip
        }
      }
    }

    return _configTooltip
  }
  getTooltip({ customMore }: { customMore?: any } = {}) {
    const row = this.row
    const configTooltip = this.getConfigTooltip({ customMore })
    if (configTooltip) {
      if (configTooltip.fTooltip && row[configTooltip.fTooltip]) {
        return row[configTooltip.fTooltip]
      } else if (configTooltip.tooltip) {
        return configTooltip.tooltip
      }
    }
  }
  getConfigModal({ customMore }: { customMore?: any } = {}) {
    const more = customMore || this.more
    if (more.configModal) {
      return more.configModal
    }
    let _configModal: IMoreOfCellConfigModal = {}
    if (more.modal) {
      _configModal = {
        title: more.modal.title
      }
    }
    return _configModal
  }

  getConfigForm({ customMore }: { customMore?: any } = {}) {
    const more = customMore || this.more
    if (more.configForm) {
      return more.configForm
    }
    let _configForm: IMoreOfCellConfigForm = {}
    if (more.formConfig) {
      return more.formConfig
    }
    return _configForm
  }

  getConfigHtml({ customMore }: { customMore?: IMoreOfCell } = {}) {
    const more = customMore || this.more
    const row = this.row
    let _configHtml: IMoreOfCellConfigHtml = {}
    if (more.configHtml) {
      if (more.configHtml.fTemplate && row[more.configHtml.fTemplate]) {
        _configHtml.template = row[more.configHtml.fTemplate]
      } else if (more.configHtml.template) {
        _configHtml.template = more.configHtml.template
      } else {
        _configHtml.template = this.getValue()
      }
      if (more.configHtml.customData) {
        _configHtml.customData = getCustomData(row, more.configHtml.customData)
      }
    } else {
      _configHtml.template = this.getValue()
    }
    return _configHtml
  }

  getFnList() {
    return this.fnList || {}
  }

  parseResponse(response: any, { customMore }: { customMore?: any } = {}) {
    const more = customMore || this.more
    const fnList = this.getFnList()
    if (response && response.Data) {
      if (response.Data.FullPath) {
        let _data = response.Data
        if (more.autoOpenLink == true) {
          fnList.openExternalUrl &&
            fnList.openExternalUrl(
              _data.FullPath,
              _data.FullPathNewWindow === false ? '_self' : '_blank'
            )
        } else {
          fnList.onShowDialog &&
            fnList.onShowDialog(
              'Link',
              <div style={{ wordWrap: 'break-word' }}>
                <div>{_data.FullPathInfo}</div>
                <a
                  href={_data.FullPath}
                  target={
                    _data.FullPathNewWindow === false ? '_self' : '_blank'
                  }
                  download={_data.FullPathDownload === true ? true : false}
                >
                  {_data.FullPath}
                </a>
              </div>
            )
        }
      }
    }
  }
  parseNeedReload(configApi: IMoreOfCellConfigApi | null, response: any) {
    const fnList = this.getFnList()
    if (configApi) {
      if (configApi.needReloadPage === true) {
        fnList.onReloadPage && fnList.onReloadPage()
        return
      }

      if (configApi.needReloadOptionsAndList) {
        fnList.onReloadList && fnList.onReloadList()
      } else if (configApi.needReloadList === true) {
        fnList.onReloadList && fnList.onReloadList()
      }

      if (configApi.needSendMsg === true) {
        fnList.onSendMsg && fnList.onSendMsg(configApi.needSendMsg)
      }
      if (configApi.needReExpand === true) {
        fnList.onReExpand && fnList.onReExpand()
      }
      if (
        configApi.needReloadRow &&
        response &&
        response.Data &&
        response.Data.Id === this.row.Id
      ) {
        fnList.onUpdateRow &&
          fnList.onUpdateRow({
            row: this.row,
            newRow: response.Data
          })
      }
    }
  }

  //static Create
  static createFromExtraData(
    extraData: any,
    fieldName: any,
    onePage: IOnePage
  ) {
    if (
      extraData &&
      extraData.Columns &&
      fieldName &&
      extraData.Columns[fieldName]
    ) {
      return new OneTableCell({
        configColumn: extraData.Columns[fieldName],
        fieldName: fieldName,
        onePage: onePage
      })
    }
    return null
  }
  static createFromCellV1(props: any) {
    return new OneTableCell(props)
  }

  static debug() {
    console.warn(OneTableCell)
  }
}

export default OneTableCell
