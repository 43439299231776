import React from "react"
import MyTableFilterControlHelpers from "src/core/components/MyTableFilterControl/MyTableFilterControlHelpers"
import { CSuppense } from "src/core/components"
const MyFilterControlSelect = React.lazy(() => import("./Types/select"))
const MyFilterControlSelect2 = React.lazy(() => import("./Types/select2"))
const MyFilterControlSelect2content = React.lazy(
  () => import("./Types/select2content")
)

const MyTFCWrap = React.lazy(() => import("./TypesWrap/v1_mtfc_wrap"))
const MyFilterControlCheckbox = React.lazy(() => import("./Types/checkbox"))
const MyTableFilterControlOne = {
  init() {
    //wrap
    MyTableFilterControlHelpers.addTypesWrap("_default", props => {
      return CSuppense(props, MyTFCWrap)
    })
    //control
    let _mapControl = {
      select: MyFilterControlSelect,
      select2: MyFilterControlSelect2,
      select2content: MyFilterControlSelect2content,
      checkbox: MyFilterControlCheckbox,
    }
    for (let k of Object.keys(_mapControl)) {
      MyTableFilterControlHelpers.addTypes(k, props => {
        return CSuppense(props, _mapControl[k])
      })
    }
  },
}

export default MyTableFilterControlOne
