import React from "react"
import { isArray } from "src/core/helpers/utils"
import Ext_Control from "./Ext_Control"
import { HIs } from "@macashipo/mlib"
class Select_Ext extends Ext_Control {
  constructor(props) {
    super(props)
    this.state = {
      value: null, //chua thay doi gia tri
    }

    //init default
    if (
      this.props.controlOpts?.defaultCheckAll === true &&
      this.props.controlOpts.handleUpdateDefaultValues
    ) {
      let _source = this.getSourceData() || []
      let _objDefault = {
        [this.getFieldName()]: _source.map((v: any, i) => {
          return v.Value
        }),
      }
      console.warn("handleUpdateDefaultValues:", _source, _objDefault)
      this.props.controlOpts.handleUpdateDefaultValues(_objDefault)
      this.forceUpdate()
      var a =
        this.props.controlOpts.getDefaultValues &&
        this.props.controlOpts.getDefaultValues()
      console.warn("handleUpdateDefaultValues after:", a)
    }
  }
  getValueState = () => {
    return this.state.value
  }
  getValueForChange = () => {
    const { value } = this.state
    if (!this.getMulti() && HIs.isArray(value)) {
      if (value.length > 0) {
        return value[0]
      }
      return ""
    }
    return value
  }
  getThisValue = () => {
    const { value } = this.state
    if (value !== null) {
      return value
    } else {
      let _defaultChecks = this.getDefaultValueCheck()
      // console.warn("getThisValue from default:", _defaultChecks)
      if (_defaultChecks && isArray(_defaultChecks)) {
        //tao object moi de tranh viec su dung tren obj default values
        return [..._defaultChecks]
      } else if (
        // Truong hop chi co 1 gia tri
        this.getPropsSelect2ByKey("simpleValue") &&
        typeof _defaultChecks !== "object"
      ) {
        return [_defaultChecks]
      } else if (!this.getMulti() && typeof _defaultChecks !== "object") {
        return [_defaultChecks]
      }
    }
    return []
  }
  getDefaultValueCheck = () => {
    const defaultValue = this.props.defaultValue
    // console.warn("getDefaultValueCheck:", defaultValue)
    return defaultValue || []
  }
  getMulti = () => {
    const { controlOpts } = this.props
    if (controlOpts && controlOpts.multi === false) {
      return false
    }
    return true //default true
  }
  getInLine = () => {
    const { controlOpts } = this.props
    if (controlOpts && controlOpts.inline === true) {
      return true
    }
    return false //default false
  }
  getFieldName = () => {
    const { controlOpts } = this.props
    if (controlOpts && controlOpts.fieldName) {
      return controlOpts.fieldName
    }
    return ""
  }
  getSourceData = () => {
    const { controlOpts } = this.props
    if (controlOpts && controlOpts.sourceData) {
      return controlOpts.sourceData
    }
    return []
  }
  getOnUpdateValue = () => {
    const { controlOpts } = this.props
    if (controlOpts && controlOpts.onUpdateValue) {
      return controlOpts.onUpdateValue
    }
    return () => {
      console.warn("Missing onUpdateValue on controlOpts", this.props)
    }
  }
  getPropsSelect2ByKey = key => {
    const { controlOpts } = this.props
    const morePropsSelect2 = controlOpts?.morePropsSelect2
    if (morePropsSelect2?.[key]) {
      return morePropsSelect2[key]
    }
    return null
  }
  isChecked = option => {
    const thisValue = this.getThisValue()
    if (thisValue !== null) {
      if (thisValue.indexOf(option.Value) > -1) {
        return true
      }
    }
    return false
  }
  clearAll = () => {
    const onUpdateValue = this.getOnUpdateValue()
    this.setState(
      {
        value: [],
      },
      () => {
        if (onUpdateValue) {
          onUpdateValue(this.state.value)
        }
      }
    )
  }
  onCheckOption = option => {
    let _value = this.getThisValue()
    const onUpdateValue = this.getOnUpdateValue()
    const isMulti = this.getMulti()
    console.warn("onCheckOption:", isMulti, _value)
    if (isMulti === false) {
      if (this.getPropsSelect2ByKey("simpleValue")) {
        //TH co 1 value khong truyen dang mang
        _value = option?.Value
      } else {
        _value = [option.Value]
      }
    } else {
      if (this.isChecked(option)) {
        let _index = _value.indexOf(option.Value)
        _value.splice(_index, 1)
      } else {
        _value.push(option.Value)
      }
    }

    this.setState(
      {
        value: _value,
      },
      () => {
        let _valueForChange = this.getValueForChange()
        console.warn("onCheckOption after:", _valueForChange)
        if (onUpdateValue) {
          onUpdateValue(_valueForChange)
        }
      }
    )
  }
  render() {
    return <div></div>
  }
}

export default Select_Ext
