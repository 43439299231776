import React from "react"
import MyControlTypes from "src/core/components/MyControl/MyControlTypes"
import { CSuppense } from "src/core/components"

const MCSelect2 = React.lazy(() => import("./Types/select2"))
const MCDate_flatpickr = React.lazy(() => import("./Types/date_flatpickr"))
const MCComponent = React.lazy(() => import("./Types/component"))

// const List = {
//   select2: MCSelect2,
//   date_flatpickr: MCDate_flatpickr
// }

const MyControlOne = {
  init() {
    MyControlTypes.Types["select2"] = props => {
      return CSuppense(props, MCSelect2)
    }
    MyControlTypes.Types["date_flatpickr"] = props => {
      return CSuppense(props, MCDate_flatpickr)
    }
    MyControlTypes.Types["component"] = props => {
      return CSuppense(props, MCComponent)
    }
  },
}

export default MyControlOne
