import React from "react"
import MyUIHelpers from "src/core/components/MyUI/MyUIHelpers"
import { CSuppense } from "src/core/components"

const MyUIPayslipCard = React.lazy(() => import("./Types/PayslipCard"))
const MyUIAvatar = React.lazy(() => import("./Types/Avatar"))
const MyUIImage = React.lazy(() => import("./Types/Image"))

const MyUIOne = {
  init() {
    MyUIHelpers.addTypes("payslip_card", props => {
      return CSuppense(props, MyUIPayslipCard)
    })
    MyUIHelpers.addTypes("avatar", props => {
      return CSuppense(props, MyUIAvatar)
    })
    MyUIHelpers.addTypes("esc-image", props => {
      return CSuppense(props, MyUIImage)
    })
  },
}

export default MyUIOne
