import { mergeObjIfExist } from '../helpers/Utils'
import {
  isObject,
  isArray,
  isFunction,
  isString,
  isBoolean
} from '../helpers/Is'
import HFilter from '../helpers/Filter'
interface IOneFilter {
  isFilterHide?: boolean
  isServer?: boolean
  isSharedDetail?: boolean
  type?: string
  more?: object
  value?: any
  valueFilterServer?: any
  fieldName?: string
}

interface IMoreOfFilter {
  isServer?: boolean
  fQuery?: string
  fieldQuery?: string //khong dung nua, chuyen sang fQuery
  fQueryStart?: string //Query dung cho datefromto
  fQueryEnd?: string //Query dung cho datefromto
  queryType?: string //Chuyen doi mang sang string
  splitChar?: string //Char convert array to string
}

class OneFilter implements IOneFilter {
  isFilterHide?: boolean = false
  isServer?: boolean = false
  isSharedDetail?: boolean = false
  type?: string = 'SelectFilter'
  more?: IMoreOfFilter = {}
  value?: any = null
  valueFilterServer?: any = null
  fieldName?: string = ''
  constructor(fieldName: string, objFilter: any) {
    this.fieldName = fieldName
    if (isObject(objFilter)) {
      mergeObjIfExist(this, objFilter)
    } else if (isString(objFilter) || isBoolean(objFilter)) {
      this.type = 'SelectFilter'
      this.value = objFilter
    }
  }

  getValueFilterServer() {
    if (this.valueFilterServer != null) {
      return this.valueFilterServer
    } else if (this.value != null) {
      return this.value
    }
  }
  getDataByThisFilter(list?: any[]) {
    console.warn('getDataByThisFilter:', list)
    if (list && list.length > 0) {
      return list.filter((v) => {
        // console.warn('filter:', v, this.type)
        return HFilter.compareTrueForBootstrapV1(
          this.type,
          this.value,
          v,
          this.fieldName,
          this
        )
      })
    }
    return []
  }
  getQueryFilterClient() {
    if (this.fieldName && this.value != null) {
      let _v = this.value
      if (isObject(_v)) {
        _v = { ...this.value }
        for (let k of this.value) {
          if (isFunction(this.value[k])) {
            delete _v[k]
          }
        }
      }
      return {
        [this.fieldName]: _v
      }
    }
    return null
  }
  getQueryFilter() {
    let _query = {}
    if (this.isServer === true) {
      let _valueServer = this.getValueFilterServer()
      let _fQuery = this.more?.fQuery || this.more?.fieldQuery || this.fieldName
      if (_fQuery && _valueServer != null) {
        _query[_fQuery] = _valueServer
        if (this.more?.queryType === 'string' && isArray(_valueServer)) {
          _query[_fQuery] = _valueServer.join(this.more?.splitChar || ',')
        }
      }
      if (isObject(_valueServer) && _valueServer.start != null) {
        let _fStart = this.more?.fQueryStart || 'FromDate'
        _query[_fStart] = _valueServer.start
      }
      if (isObject(_valueServer) && _valueServer.end != null) {
        let _fEnd = this.more?.fQueryEnd || 'ToDate'
        _query[_fEnd] = _valueServer.end
      }

      //delete query trùng trong cùng 1 filter
      if (this.more?.fQuery || this.more?.fQueryStart || this.more?.fQueryEnd) {
        if (
          this.fieldName &&
          this.fieldName != this.more.fQuery &&
          _query[this.fieldName]
        ) {
          delete _query[this.fieldName]
        }
      }
    }

    return _query
  }
  static debug() {
    console.warn(OneFilter)
  }
}

export default OneFilter
