import { isJsonString, isObject, isString } from './Is'
import { json2Obj } from './Json'
import { mergeObjIfExist } from './Utils'
import { logNeedOveride } from './Log'

export const parseMsg = function (msg: any) {
  let _obj = {
    msg: '',
    ui: 'toast', //toast,alert,system
    type: 'success'
  }
  if (isString(msg)) {
    _obj.msg = msg
    if (isJsonString(msg)) {
      let _jsonObj = json2Obj(msg)
      if (_jsonObj) {
        mergeObjIfExist(_obj, _jsonObj)
      }
    } else if (msg.startsWith('[Warning]')) {
      _obj.type = 'warning'
      _obj.msg = msg.replace('[Warning]', '')
    } else if (msg.startsWith('[Error]')) {
      _obj.type = 'danger'
      _obj.msg = msg.replace('[Error]', '')
    }
  } else if (isObject(msg)) {
    mergeObjIfExist(_obj, msg)
  }
  return _obj
}

export function initInnerRef(component: any) {
  if (component && component.props && component.props.innerRef) {
    component.props.innerRef(component)
  }
}

/**
 * Need overide in app
 */

export const HUI_NeedOveride = {
  showDialog(configDialog: any) {
    logNeedOveride(configDialog)
  },
  showToast(configToast: any) {
    logNeedOveride(configToast)
  },
  showMsg(configMsg: any, defaultObjMsg: any = {}) {
    logNeedOveride(configMsg, defaultObjMsg)
  },
  showLoadingScreen(configLoadingScreen: any) {
    logNeedOveride(configLoadingScreen)
  }
}

export function showDialog(configDialog: any) {
  HUI_NeedOveride.showDialog(configDialog)
}
export function showToast(configToast: any) {
  HUI_NeedOveride.showToast(configToast)
}
export function showMsg(configMsg: any, defaultObjMsg: any = {}) {
  HUI_NeedOveride.showMsg(configMsg, defaultObjMsg)
}
export function showLoadingScreen(configLoadingScreen: any) {
  HUI_NeedOveride.showLoadingScreen(configLoadingScreen)
}

export const hideInMyModalFromProps = (props: any) => {
  console.warn('hideInMyModalFromProps:', props)
  if (props && props.inMyModal && props.inMyModal.hide) {
    props.inMyModal.hide()
  }
}
