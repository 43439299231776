const HConstant = {
  Modules: {
    FnList: {
      runAfterInit: "runAfterInit",
    },
  },
  ConfigAppExt: {
    footer: "footer",
    dateFormat: "dateFormat",
    urlForLogException: "urlForLogException",
  },
  ConfigApp: {
    FnList: {
      onAfterInit: "onAfterInit",
    },
  },
  Dev: {
    Key: {
      _DEBUG_: "_DEBUG_",
    },
  },
  Api: {
    Name: {
      List: "List",
      UpdateFields: "UpdateFields",
    },
  },
  HConfig: {
    FnList: {
      addPublicRoutes: "addPublicRoutes",
      addPrivateRoutes: "addPrivateRoutes",
      registerAfterLogin: "registerAfterLogin",
      registerExtSettingParts: "registerExtSettingParts",
      registerHeaderTitle: "registerHeaderTitle",
      dispatchFromStore: "dispatchFromStore",
      updateHeaderTitle: "updateHeaderTitle",
    },
  },
  MyTableCell: {
    FnList: {
      onUpdateValue: "onUpdateValue",
    },
    // UIRowField: {
    //   _UI_JustUpdated: "_UI_JustUpdated",
    // },
  },
  MyFormControl: {
    FnList: {
      onUpdateValue: "onUpdateValue",
    },
  },
  Auth: {
    dispatchLoginSuccess: "dispatchLoginSuccess",
  },
  ExtModulesName: {
    sidenavitems: "sidenavitems",
  },
  ScreenConfig: {
    Header: {
      showProjectHeader: "showProjectHeader",
      showAddNew: "showAddNew",
    },
    Table: {
      isCheckInvalidProject: "isCheckInvalidProject",
      tableProps: "tableProps",
      "tableProps.hover": "hover",
      hasExpand: "hasExpand",
      showDetails: "showDetails",
      canDelete: "canDelete",
    },
    Detail: {
      titleField: "titleField",
      controller: "controller",
      screenCode: "screenCode",
      queryFromParent: "queryFromParent",
    },
  },
}

export default HConstant
