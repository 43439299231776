import MyModal from "./MyModal"
const MyDialog = {
  show({ title = "", msg = "" } = {}) {
    MyModal.showMyDialog({
      title: title,
      component: msg,
      modalCentered: true,
      size: "md",
      showFooter: true,
      backdrop: "static",
    })
  },
}

export default MyDialog
