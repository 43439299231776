import React, { lazy, Suspense } from "react"
import { Router, Switch, Route } from "react-router-dom"
import { HLink, HConfig } from "src/core/helpers"
import AuthenticatedGuard from "src/guards/AuthenticatedGuard"
import PageGeneric from "src/pages/PageGeneric/PageGeneric"
// import PageNotFound from "src/pages/PageNotFound"
import { PATH } from "src/helpers/paths"
import Loading from "src/components/Loading/Loading"
import MainLayout from "src/layouts/MainLayout"
// import { CSuppense } from "src/core/components"
import MyPage from "src/core/components/MyPage/MyPage"
// const Home = lazy(() => import("src/pages/Home/Home"))
const Login = lazy(() => import("src/pages/Login/Login"))
const PageNotFoundv2 = lazy(() => import("src/pages/PageNotFound/demo2"))

export default function Routes() {
  const history = HLink.getHistory()
  const publicRoutes = HConfig.getPublicRoutes()
  const privateRoutes = HConfig.getPrivateRoutes()
  // console.warn("history:", history)
  return (
    <Router history={history}>
      <Switch>
        <AuthenticatedGuard
          exact
          path={PATH.HOME}
          component={
            props => {
              const configPage = {
                UIUrl: "/",
                ScreenCode: "PayslipList",
                Title: "Payslip",
                APIName: "ReportMyPayOff",
                UIType: "paysliplist",
                Config: null,
                RequestData: "",
                Id: "",
              }
              return (
                <MainLayout {...props}>
                  <MyPage configPage={configPage} />
                </MainLayout>
              )
            }
            // (
            //   <MainLayout {...props}>{CSuppense(props, Home)}</MainLayout>
            // )
          }
        />
        <Route
          path={PATH.LOGIN}
          component={() => (
            <Suspense fallback={<Loading />}>
              <Login />
            </Suspense>
          )}
        />
        {privateRoutes?.map((v, i) => {
          return (
            <AuthenticatedGuard
              key={i}
              path={v.UIUrl || ""}
              component={props => <PageGeneric {...props} configPage={v} />}
            />
          )
        })}
        {publicRoutes?.map((v, i) => {
          return (
            <Route
              key={i}
              path={v.UIUrl || ""}
              render={props => {
                return <PageGeneric {...props} configPage={v} />
              }}
            />
          )
        })}
        <Route
          key={"all"}
          path={"*"}
          exact={true}
          render={props => {
            return (
              <Suspense fallback={<Loading />}>
                <PageNotFoundv2 />
              </Suspense>
            )
          }}
        />
      </Switch>
    </Router>
  )
}
