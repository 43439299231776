import { HFormat, HIs } from "@macashipo/mlib"
import HC from "./c"
import moment from "moment"

HFormat.Types["date"] = {
  format: (value: any, configFormat: any) => {
    console.warn("format date:", value, configFormat)
    if (HIs.isDateValue(value)) {
      return moment(value).format(
        configFormat.format || HC.getDefaultFormatDate()
      )
    }
    return ""
  },
}

export default HFormat
