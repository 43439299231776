import { HConstant, HUtils } from "src/core/helpers"
import MyPageOne from "./MyPage"
import MyUIOne from "./MyUI"
import MyIconOne from "./MyIcon"
import MyControlOne from "./MyControl"
import MyFormControlOne from "./MyFormControl"
import "./assets/theme-esc.scss"

interface IModuleAppESC extends ICore.IOneModule {
  runAfterInit: (HConfig: any) => void
}
const ModuleAppESC: IModuleAppESC = {
  _key: "app_esc",
  _fnList: {},
  _config: {},
  getMyKey: () => {
    return "app_esc"
  },
  runAfterInit: (HConfig: any) => {
    MyPageOne.init()
    MyUIOne.init()
    MyIconOne.init()
    MyControlOne.init()
    MyFormControlOne.init()
    window.document.body.classList.add("theme-esc")
    HUtils.runFuntion(HConfig, HConstant.HConfig.FnList.addPrivateRoutes, [
      [
        {
          Id: "myprofile",
          UIUrl: "/my-profile",
          UIType: "myprofile",
        },
        {
          Id: "payslipdetail",
          UIUrl: "/p/:id",
          UIType: "payslipdetail",
          Title: "Payslip Details",
        },
      ],
    ])
  },
}

export default ModuleAppESC
