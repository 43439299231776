import React from "react"
import { MyControlHelpers } from "@macashipo/mlib"
const MC_esc_date_flatpickr = React.lazy(
  () => import("./Types/esc_date_flatpickr")
)
const List = {
  esc_date_flatpickr: MC_esc_date_flatpickr,
}
const MyControlOne = {
  init() {
    MyControlHelpers.addTypesFromListLazy(List)
  },
}

export default MyControlOne
