import { HLog } from "src/core/helpers"
import ModuleFirstSetting from "src/core/modules/firstsetting"
// import ModuleCategoryScreen from "src/core/modules/categoryscreen"
import ModuleV1Page from "src/core/modules/v1page"
import ModuleDev from "src/core/modules/dev"
import ModuleIconHiOutline from "src/core/modules/iconHiOutline"
import ModuleSetting from "src/core/modules/setting"
import ModuleSetting2FA from "src/core/modules/setting2fa"
import ModuleRedirectToken from "src/core/modules/redirectToken"
import ModuleDevSimulator from "src/core/modules/dev_simulator"
import ModuleAppESC from "src/modules/app_esc"
import ModuleReactGA from "src/modules/reactga"
import ModuleCore from "src/core/modules/core"

import Lang_en from "../assets/i18n/en.json"
import Lang_vi from "../assets/i18n/vi.json"

import { store } from "src/store/store"
import { logout, updateHeaderTitle } from "src/App/App.actions"
import "src/assets/scss/theme-layout.css"
const AppConfig: IConfigApp = {
  appInfo: {
    title: "ESC",
    appName: "esc",
    home: "/",
  },
  configApi: {
    baseUrl: "http://escpayslip-dev-api.allianceitsc.com",
    fnLogout: () => {
      // console.warn("logout")
      store.dispatch(logout())
    },
  },
  configI18n: {
    lng: "en",
    defaultNS: "common",
    resources: {
      en: {
        common: Lang_en,
      },
      vi: {
        common: Lang_vi,
      },
    },
  },
  configAuth: {
    afterLogin: () => {
      // HLog.logW("afterLogin")
    },
  },
  fnList: {
    dispatchFromStore(action) {
      store.dispatch(action)
    },
    updateHeaderTitle: title => {
      store.dispatch(updateHeaderTitle(title))
    },
    onAfterInit: function (HConfig) {
      HLog.logW("After init", HConfig)
    },
  },
  modules: [
    ModuleCore,
    ModuleDev,
    ModuleDevSimulator,
    ModuleFirstSetting,
    // ModuleCategoryScreen,
    ModuleV1Page,
    ModuleIconHiOutline,
    ModuleSetting,
    ModuleSetting2FA,
    ModuleRedirectToken,
    ModuleAppESC,
    ModuleReactGA,
  ],
  UIAppConfig: {
    numeralFormat: "0,0.[0]",
    numeralFormatMoney: "0,0.[00]",
    isCheckInvalidProjectId: true,
    ignoreNoProjectId: false,
    timeFormat: "HH:mm",
    dateFormat: "DD-MMMYY", //MomentJS
    datetimeFormat: "DD-MMMYY HH:mm", //MomentJS
    widthMobile: 1000,
    symbolDecimal: ",",
    symbolThousand: ".",
    expandBgColor: "#e4e5e6", //#93d4e3
  },
}

export default AppConfig
