import React from "react"
import { MyUIHelpers } from "@macashipo/mlib"

const MUI_html = React.lazy(() => import("./Types/html"))

const List = {
  html: MUI_html,
}
const MyUIOne = {
  init() {
    MyUIHelpers.addTypesFromListLazy(List)
  },
}

export default MyUIOne
