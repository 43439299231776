import React, { useEffect } from "react"
import { connect, ConnectedProps } from "react-redux"
import { useHistory } from "react-router-dom"
import { logout, toggleSideNav } from "src/App/App.actions"
import { HAuth, HConfig } from "src/core/helpers"
import { PATH } from "src/helpers/paths"
// import { HLog } from "src/core/helpers"
import Avatar from "./Avatar"
// import Notify from "./Notify"
// import HeaderTitle from "./HeaderTitle"
// import { MyIcon } from "src/core/components"
// import { HConfig } from "src/core/helpers"
import { LogoApp } from "./Header.styles"
const mapStateToProps = state => ({})

const mapDispatchToProps = {
  logout,
  toggleSideNav,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

interface Props extends ConnectedProps<typeof connector> {}

const Header = (props: Props) => {
  const { logout } = props
  // const { currentHeaderTitle } = useShareableState()
  const history = useHistory()
  const handleLogout = () => {
    logout()
    history.push(PATH.LOGIN)
  }

  useEffect(() => {}, [history])
  return (
    <header className="c-header d-flex bg-light justify-content-between p-1 shadow-sm">
      {/* <button className="btn btn-primary" onClick={toggleSideNav}>
        <MyIcon data="type:HiMenu" size={26} />
      </button> */}
      <div
        className="d-flex justify-content-start flex-grow-1"
        style={{ minWidth: "40px" }}
      >
        {/* <HeaderTitle /> */}
        <LogoApp>
          <a href="/">
            <img
              src={HAuth.getUserLogoApp() || "/config/logo.png"}
              alt={HConfig.appInfo.appName}
            />
          </a>
        </LogoApp>
      </div>
      <ul className="navbar-nav navbar-nav-icons ml-auto d-flex flex-row align-items-center mb-0">
        {/* <li className="nav-item">
          <Notify />
        </li> */}
        <li className="nav-item">
          <Avatar handleLogout={handleLogout} />
        </li>
      </ul>
    </header>
  )
}

export default connector(Header)
