import React, { ReactNode } from "react"
import Header from "src/components/Header/Header"
import SideNav from "src/components/SideNav/SideNav"
import { HConfig } from "src/core/helpers"
import { MyUI } from "@macashipo/mlib"
interface Props {
  children: ReactNode
}

export default function MainLayout(props: Props) {
  const { children } = props
  // console.warn("MainLayout", props)
  const configFooter = HConfig.getConfigFooter()
  console.warn("show footer:", configFooter)
  return (
    <div className="c-app d-flex align-items-stretch">
      <SideNav />
      <main className="c-wrapper flex-grow-1 mw-100 overflow-auto min-vh-100">
        <Header />
        <div className="c-body content p-3">{children}</div>
        {configFooter.show === true && (
          <footer className="c-footer">
            <MyUI type="html" html={configFooter.footer} />
          </footer>
        )}
      </main>
    </div>
  )
}
