import { HCF } from "@macashipo/mlib"
import { HApi } from "../helpers"
interface IModuleFirstSetting extends ICore.IOneModule {
  requestFirstSetting: () => void
  parseFirstSetting: (data: object) => void
}
const ModuleFirstSetting: IModuleFirstSetting = {
  _key: "first_setting",
  _fnList: {},
  _config: {},
  getMyKey: () => {
    return "first_setting"
  },
  runAfterInit: (HConfig: any) => {
    ModuleFirstSetting.requestFirstSetting()
  },

  requestFirstSetting: () => {
    HApi.apiPost({
      path: "GlobalAppSetting",
      name: "FisrtSetting",
      data: {},
    })
      .then(response => {
        if (response.Data) {
          ModuleFirstSetting.parseFirstSetting(response.Data)
        }
      })
      .catch(error => {})
  },
  parseFirstSetting: data => {
    if (data) {
      HCF.setCFMultiObj(data)
      if (data["DefaultValues"]) {
        HCF.setCFMultiObj(data["DefaultValues"])
      }
    }
  },
}

export default ModuleFirstSetting
