import MyPageOne from "./MyPage"
import { runFuntion, isLocalDev } from "src/core/helpers/utils"
import HConstant from "src/core/helpers/constant"
import * as actions from "src/App/App.actions"
interface IModuleDev extends IModuleOne {
  runAfterInit: (HConfig: any) => void
}
const ModuleDev: IModuleDev = {
  runAfterInit: HConfig => {
    if (isLocalDev()) {
      MyPageOne.init()
      //update routes
      runFuntion(HConfig, HConstant.HConfig.FnList.addPublicRoutes, [
        [
          {
            Id: "dev1",
            UIUrl: "/dev/1",
            UIType: "dev_listmycontrol",
          },
          {
            Id: "dev2",
            UIUrl: "/dev/categorylist",
            UIType: "dev_categorylist",
          },
          {
            Id: "dev2",
            UIUrl: "/dev/mypage",
            UIType: "dev_mypage",
          },
          {
            Id: "devmyform",
            UIUrl: "/dev/myform",
            UIType: "dev_myform",
          },
          {
            Id: "devnotification",
            UIUrl: "/dev/notification",
            UIType: "dev_notification",
          },
          {
            Id: "devother",
            UIUrl: "/dev/other",
            UIType: "dev_other",
          },
          {
            Id: "devresize",
            UIUrl: "/dev/resize",
            UIType: "dev_resize",
          },
          {
            Id: "dev_hook",
            UIUrl: "/dev/hook",
            UIType: "dev_hook",
          },
          {
            Id: "dev_customhook",
            UIUrl: "/dev/customhook",
            UIType: "dev_customhook",
          },
        ],
      ])

      //update sidenav
      runFuntion(HConfig.fnList, HConstant.HConfig.FnList.dispatchFromStore, [
        actions.sideNavUpdate([
          {
            isDev: true,
            name: "Dev 1",
            url: "/dev/1",
          },
          {
            isDev: true,
            name: "Dev Category List",
            url: "/dev/categorylist",
          },
          {
            isDev: true,
            name: "Dev My Page",
            url: "/dev/mypage",
          },
          {
            isDev: true,
            name: "Dev My Form",
            url: "/dev/myform",
          },
          {
            isDev: true,
            name: "Dev Other",
            url: "/dev/other",
          },
        ]),
      ])
    }
  },
}

export default ModuleDev
