import { saveObj, saveString, getString, getObj } from "./localStorage"
import { mergeObjIfExist } from "./utils"
import ImgAvatar from "src/assets/images/avatar.jpg"
const AuthInfo: any = {
  kAuthToken: "Auth-Token",
  kAuthType: "Auth-Type",
  kAuthInfo: "Auth-Info",
  kAuthInfoSocial: "Auth-InfoSocial",
  token: "",
  type: "Bearer",
  info: {},
  infoSocial: null,
  info2FA: {},
}

const AuthConfig: IAuthConfig = {
  afterLogout: () => {},
  afterLogin: () => {},
}

export const initAuth = (config?: IAuthConfig) => {
  mergeObjIfExist(AuthConfig, config)
  loadLastSaved()
}
export const authCheckSavedAuthorized = () => {
  let _token = getString(AuthInfo.kAuthToken, "")
  if (_token) {
    return true
  }
  return false
}

export const authGetAuthorizationHeader = () => {
  // console.warn("authGetAuthorizationHeader:", AuthInfo)
  if (AuthInfo.token) {
    return `${AuthInfo.type} ${AuthInfo.token}`
  }
}

const loadLastSaved = () => {
  // AuthInfo.auth = data;
  let _obj = {
    type: getString(AuthInfo.kAuthType, AuthInfo.type),
    token: getString(AuthInfo.kAuthToken, ""),
    info: getObj(AuthInfo.kAuthInfo, {}),
  }
  if (_obj.token) {
    authLogin(_obj)
  }
}
const savedLocal = () => {
  saveString(AuthInfo.kAuthType, AuthInfo.type)
  saveString(AuthInfo.kAuthToken, AuthInfo.token)
  saveObj(AuthInfo.kAuthInfo, AuthInfo.info)
}

export const authLogin = (data: IAuth) => {
  mergeObjIfExist(AuthInfo, data)
  savedLocal()
  // console.warn("authLogin:", data)
  if (AuthConfig.afterLogin) {
    AuthConfig.afterLogin()
  }
}

const getInfoFromData = function (data: any) {
  let _info = {}
  for (let k of Object.keys(data)) {
    if (k !== "access_token" && k !== "token_type") {
      _info[k] = data[k]
    }
  }
  return _info
}
const parse2FAfromData = function (data: any) {
  if (data) {
    AuthInfo.info2FA["fa2_needenable"] = data.fa2_needenable
    AuthInfo.info2FA["fa2_needverify"] = data.fa2_needverify
    AuthInfo.info2FA["fa2_token"] = data.fa2_token
    AuthInfo.info2FA["user_uniqueid"] = data.user_uniqueid
    AuthInfo.info2FA["user_id"] = data.user_id
    AuthInfo.info2FA["fa2_method"] = data.fa2_method
  }
}
export const authLoginWithRes = (data: any): boolean => {
  // console.warn("authLoginWithRes:", data)
  parse2FAfromData(data)
  if (data && data.access_token) {
    let _info = getInfoFromData(data)
    let _obj = {
      token: data.access_token,
      type: data.token_type,
      info: _info,
    }
    authLogin(_obj)
    return true
  }
  return false
}
export const authLoginSocialWithRes = (data: any): boolean => {
  if (data) {
    if (data.Token) {
      parse2FAfromData(data.Token)
    }
    if (data.Token && data.Token.access_token) {
      let _info = getInfoFromData(data.Token)
      let _obj = {
        token: data.Token.access_token,
        type: data.Token.token_type,
        info: _info,
        infoSocial: data.SocialInfo,
      }
      authLogin(_obj)
      return true
    }
  }
  return false
}

export const authLogout = () => {
  localStorage.removeItem(AuthInfo.kAuthToken)
  localStorage.removeItem(AuthInfo.kAuthInfo)
  localStorage.removeItem(AuthInfo.kAuthType)
  AuthInfo.info2FA = {}
  AuthInfo.info = {}
  AuthInfo.token = ""
  if (AuthConfig.afterLogout) {
    AuthConfig.afterLogout()
  }
}

export const authIsLogged = () => {
  return AuthInfo.token ? true : false
}

export const getUserInfo = (): any => {
  return AuthInfo.info
}

export const getInfo2FA = () => {
  return AuthInfo.info2FA
}

export const getUserID = () => {
  return getUserInfo()?.user_id
}

export const getUserName = () => {
  return getUserInfo()?.name
}

export const getUserEmail = () => {
  return getUserInfo()?.user_name
}

export const getUserAvatar = () => {
  if (getUserInfo() && getUserInfo()["avatar_url"]) {
    return getUserInfo()?.avatar_url
  }
  return ImgAvatar
}

export const getUserLogoApp = () => {
  return getUserInfo()?.appLogo_url
}

export const checkShow2FAFromTokenResponse = resToken => {
  if (resToken) {
    if (resToken.fa2_needenable || resToken.fa2_needverify) {
      return true
    }
  }
  return false
}
