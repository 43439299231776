import styled from "styled-components"

export const LogoutIcon = styled.span``

export const LogoApp = styled.div`
  height: 50px;
  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`
