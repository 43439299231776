import React from "react"
import { MyIconHelpers } from "@macashipo/mlib"

// const MyIconCoin = React.lazy(() => import("./Types/coin"))
// const MyIconBonusCoin = React.lazy(() => import("./Types/bonus_coin"))
// const MyIconOvertime = React.lazy(() => import("./Types/overtime"))
const MyIconesc_filter = React.lazy(() => import("./Types/esc_filter"))
const MyIconesc_back = React.lazy(() => import("./Types/esc_back"))

const List = {
  // coin: MyIconCoin,
  // bonus_coin: MyIconBonusCoin,
  // overtime: MyIconOvertime,
  esc_filter: MyIconesc_filter,
  esc_back: MyIconesc_back,
}
const MyIconOne = {
  init() {
    MyIconHelpers.addTypesFromListLazy(List)
  },
}

export default MyIconOne
